import React, { Component } from "react";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import SEO from "../components/Seo";
import NotFound from "../images/not_found.webp";

export default class ErrorPage extends Component {
  render() {
    return (
      <div className="relative bg-white overflow-hidden">
        <SEO
          title="DMAP - Not Found"
          description="This page is unable to be found on DMAP's Website.DMAP (Digital Makers Asia Pacific is a team of Software Engineers and Developers that got together by participating in one or more of the APEC App Challenge events."
        />
        <Hero
          title="Page Not Found"
          titleBlue="404"
          description="Oops, we are unable to find the page that you have requested. Do you want to try browsing through available links or return to the home page ?"
          link="/"
          linkTitle="Back To Home"
          image={NotFound}
        />
        <Footer />
      </div>
    );
  }
}
